import { BackButton, BREAKPOINTS, Button, COLORS, ComponentL, ComponentTextStyle, ContentIcons, HorizontalCard, HyperLink, LoadingIndicator, PageWidth, Size, SystemIcons } from "@laerdal/life-react-components";

import styled from "styled-components";
import LaerdalCustomerCare from "../../commonComponents/LaerdalCustomerCare";
import UrgentAssistance from "../../commonComponents/UrgentAssistance";
import { caseTypeSubtypes } from "../../model/constants/CaseTypeSubtype";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ArticleApi from "../../services/api/ArticleApi";
import { Faq } from "@laerdal/life-react-components/dist/icons/contenticons";
import Faqs from "../../commonComponents/Faqs";
import { PageWidthFull } from "../../commonComponents/PageWidthFull";


const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0;
`;
const Header = styled.div`
    margin: 0 0 46px 0;
`;

const H3 = styled.div`
    color: var(--Base-Black, #1F1F1F);
    /* Content/H3 */
    font-family: var(--font-family-Title, Lato);
    font-size: var(--font-size-H3, 40px);
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
`;

const FullWidth = styled.div`
  border-top: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.neutral_20};
`

const Options = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Side = styled.div`
    display: flex;
    flex-direction: column;
    gap:16px;
`

const Row = styled.div`
    display: flex;
    ${BREAKPOINTS.MEDIUM}{
        flex-direction: row;
        gap:64px
    }

    flex-direction: column;
    gap:8px
`
const UsfulArticles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 64px;
`

const Link = styled(HyperLink)`
    border-radius: 4px;
    border: 1px solid var(--Neutral-200-tint, #CCC);
    background: var(--Base-White, #FFF);
    padding: 12px 20px 7px 20px;

    svg {
        vertical-align: middle;
        margin: 0 8px 4px 0;
    }
`;


const CanNotSpecifyBtn = styled(Button)`
    align-self: start;
    margin: 8px 0;
`;


const CaseSuboptionsPage = () => {
    const { option } = useParams<{ option: string}>();
    const navigate = useNavigate();
    
    const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);
    const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
    
    const caseType = caseTypeSubtypes.find((caseType) => caseType.value === option);
    
    useEffect(() => {
        const tempCaseType = caseTypeSubtypes.find((caseType) => caseType.value === option)

        ArticleApi.GetKnowledgeArticles(4, 0, tempCaseType?.title ?? '', '')
            .then((data) => {
            setArticles(data.items);
            setArticlesLoading(false);
        });
    }, []);
    

    return (
        <>
            <PageWidthFull useMaxWidth={true} maxWidth={1024} unsetMargin={true}>
                <PageContainer>
                    <Header>
                        <BackButton size={Size.Small} onClick={() => window.history.back()}  >
                            Back 
                        </BackButton>
                        <H3>{caseType?.title}</H3>
                        <ComponentL color={COLORS.neutral_600}>{caseType?.subtitle}</ComponentL>
                    </Header>

                    <Row>
                        <Options>
                            {caseType?.subtypes.map((subtype, index) => (
                                <HorizontalCard 
                                    key={index}
                                    title={subtype.title}
                                    action={() => navigate(`/case/create/${caseType.value}/${subtype.value}`)}
                                />
                            ))}
                            <CanNotSpecifyBtn variant="tertiary" onClick={() => navigate(`/case/create/unknown/unknown`)}>Can not specify?</CanNotSpecifyBtn>
                        </Options>
                        <Side>
                            <Link variant="default" href="https://laerdal.com/support/catalog/" ><SystemIcons.ShoppingCart size="24px"/>Shop for parts, and accessories</Link>
                            <Link variant="default" href="https://laerdal.com/support/scheduled-support/" ><SystemIcons.Educator size="24px"/>Schedule a call with technician</Link>
                        </Side>
                    </Row>
                </PageContainer>
            </PageWidthFull>
            <FullWidth>
                <PageWidth useMaxWidth={true} maxWidth={1024}>
                    { articlesLoading && <LoadingIndicator/>}
                    { articles.length > 0 && 
                        <UsfulArticles>
                            <ComponentL textStyle={ComponentTextStyle.Bold}>Useful articles</ComponentL>
                            <Faqs
                                items={
                                    articles.map((x)=> ({
                                        text: x.title,
                                        link: `/articles/${x.articleNumber}`
                                    }))
                                } />
                        </UsfulArticles>
                    }
                    <UrgentAssistance />
                </PageWidth>
            </FullWidth>
        </>);
};

export default CaseSuboptionsPage;