import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    accountNumber: string | null;
    accountName: string | null;
    country: string | null;
    countryCode2: string | null;
}

const initialState: UserState = {
    firstName: null,
    lastName: null,
    email: null,
    accountNumber: null,
    accountName: null,
    country: null,
    countryCode2: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUserData(state, action: PayloadAction<UserState>) {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.accountNumber = action.payload.accountNumber;
            state.accountName = action.payload.accountName;
            state.country = action.payload.country;
            state.countryCode2 = action.payload.countryCode2;
        },
    },
});

export const { saveUserData } = userSlice.actions;
export default userSlice.reducer;
