import { BREAKPOINTS, Button, COLORS, HyperLink, Size, SystemIcons } from '@laerdal/life-react-components';
import { Link } from '@laerdal/life-react-components/dist/icons/systemicons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageWidthFull } from './PageWidthFull';


const FullPageContainer = styled.div`
  display:flex;
  background: ${COLORS.neutral_20};
  border-bottom: 1px solid #CCC;
`;

const DownloadLink = styled(HyperLink)`
    font-size: 20px;

    svg {
      padding-bottom: 7px;
      vertical-align: middle;
    }
`;

const TopSectionContainer = styled.div`
  ${BREAKPOINTS.LARGE}{
    margin: 32px auto;
  }
  margin: 32px;
  max-width: 970px;
`;
const Breadcrumb = styled.div`
  padding:12px 0;
  margin-top: 32px;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 32px;
`;
const LeftColumn = styled.div`
  display:flex;
  flex-direction:column;
  gap: 16px;
    flex: 1;
`;
const RightColumn = styled.div`
  display: none;
  ${BREAKPOINTS.MEDIUM}{
    display:flex;
  }
  justify-content: center;
  flex-direction: column;
  flex: 1;
  img { 
    width: 80%;
  }
`;
const Title = styled.div`
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
`;
const Description = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
`;


const CrumbLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const CrumbText = styled.span`
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const Devider = styled.span`
  padding: 0 10px;
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

interface Props {
  title: string,
  description: string,
  asset: string,
  buttonText?: string,
  buttonLink?: string,
  paligoUserGuideUrl?: string,
  breadcrumb: Crumb[]
}
interface Crumb {
  text: string;
  link?: string;
}

const ProductTopSection = (props: Props) => {    
  const navigate = useNavigate()
  
  return (
      <FullPageContainer>
        <PageWidthFull useMaxWidth={true} maxWidth={1024} unsetMargin={true}>
        <Breadcrumb>
            { props.breadcrumb.map((item, index) =>
                <>
                  {!!item.link &&
                    <CrumbLink 
                      href={item.link}>
                        {item.text}
                    </CrumbLink>
                  }
                  {!item.link &&
                    <CrumbText>
                        {item.text}
                    </CrumbText>
                  }
                  
                  {(props.breadcrumb.length != index + 1) &&
                    <Devider>/</Devider>
                  }
                </>
              )
            }
          </Breadcrumb>
          <ColumnContainer>
            <LeftColumn>
              <Title>{props.title}</Title>
              <Description>
                <span dangerouslySetInnerHTML={{ __html: props.description }} />
              </Description>
                { !! props.paligoUserGuideUrl &&
                  
                  <DownloadLink 
                    href={props.paligoUserGuideUrl} 
                    variant='default' 
                    target='_blank' 
                      onClick={(e)=> {
                        e.preventDefault()
                        // @ts-ignore
                        posthog.capture('SUPP-PaligoUserGuide',{
                          title: props.title,
                          currentUrl: window.location.href,
                          targetUrl: props.paligoUserGuideUrl,
                        });
                        window.open(props.paligoUserGuideUrl, '_blank');
                      }}
                    >
                    User guide <SystemIcons.OpenNewWindow size='28px' />
                  </DownloadLink>
                }
                { !! props.buttonLink && 
                  <>
                    <DownloadLink 
                      href={props.buttonLink} 
                      variant='default' 
                      target='_blank' 
                        onClick={(e)=> {
                          e.preventDefault()
                          // @ts-ignore
                          posthog.capture('SUPP-Downloads',{
                            title: props.title,
                            currentUrl: window.location.href,
                            targetUrl: props.buttonLink,
                          });
                          window.open(props.buttonLink, '_blank');
                        }}
                       >
                      Downloads for {props.title} <SystemIcons.OpenNewWindow size='28px' />
                    </DownloadLink>
                  </>
                }
            </LeftColumn>
            <RightColumn>
              <img src={props.asset} alt={props.title} />
            </RightColumn>
          </ColumnContainer>
        </PageWidthFull>
        {/* <TopSectionContainer>
          <Breadcrumb>
            { props.breadcrumb.map((item, index) =>
                <>
                  {!!item.link &&
                    <CrumbLink 
                      href={item.link}>
                        {item.text}
                    </CrumbLink>
                  }
                  {!item.link &&
                    <CrumbText>
                        {item.text}
                    </CrumbText>
                  }
                  
                  {(props.breadcrumb.length != index + 1) &&
                    <Devider>/</Devider>
                  }
                </>
              )
            }
          </Breadcrumb>
          <ColumnContainer>
            <LeftColumn>
              <Title>{props.title}</Title>
              <Description>
                {props.description}
              </Description>
                { !! props.paligoUserGuideUrl &&
                  
                  <DownloadLink 
                    href={props.paligoUserGuideUrl} 
                    variant='default' 
                    target='_blank' 
                      onClick={(e)=> {
                        e.preventDefault()
                        // @ts-ignore
                        posthog.capture('SUPP-PaligoUserGuide',{
                          title: props.title,
                          currentUrl: window.location.href,
                          targetUrl: props.paligoUserGuideUrl,
                        });
                        window.open(props.paligoUserGuideUrl, '_blank');
                      }}
                    >
                    User guide <SystemIcons.OpenNewWindow size='28px' />
                  </DownloadLink>
                }
                { !! props.buttonLink && 
                  <>
                    <DownloadLink 
                      href={props.buttonLink} 
                      variant='default' 
                      target='_blank' 
                        onClick={(e)=> {
                          e.preventDefault()
                          // @ts-ignore
                          posthog.capture('SUPP-Downloads',{
                            title: props.title,
                            currentUrl: window.location.href,
                            targetUrl: props.buttonLink,
                          });
                          window.open(props.buttonLink, '_blank');
                        }}
                       >
                      Downloads for {props.title} <SystemIcons.OpenNewWindow size='28px' />
                    </DownloadLink>
                  </>
                }
            </LeftColumn>
            <RightColumn>
              <img src={props.asset} alt={props.title} />
            </RightColumn>
          </ColumnContainer>
        </TopSectionContainer> */}
      </FullPageContainer>
  );
}

export default ProductTopSection;