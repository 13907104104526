import { BackButton, BREAKPOINTS, Card, COLORS, ComponentTextStyle, ComponentXL, ContentIcons, GlobalNavigationBar, HorizontalCard, PageWidth, Size, SystemIcons } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import posthog from 'posthog-js';
import {useMediaMatch} from "rooks";

const Contact = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: ${COLORS.neutral_50};
`;

const HorizontalCardFullWidth = styled(HorizontalCard)`
  width: 100%;
  div > div:nth-child(2) {
    flex:unset;
    width: 100%;
  }
`
const UrgentAssistance = () => {    
    const navigate = useNavigate()
    const isSmallScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  
    return (
          <Contact>
            <ComponentXL textStyle={ComponentTextStyle.Bold}>
              Need urgent assistance? 
            </ComponentXL>

            <HorizontalCardFullWidth
                icon={ !isSmallScreen ? undefined : <ContentIcons.Call />}
                variant="elevated"
                title="Contact us"
                description="If you have an matter that requires immediate assistance, please contact Laerdal customer service directly."
                action={() => { 
                  posthog?.capture('SUPP-GetUrgentAssistance',{
                    url: window.location.href,
                    ref: document.referrer
                  });
                  
                  window.location.href = 'https://laerdal.com/support/customer-service/contact-us/';
                }}/>
          </Contact>
    );
}

export default UrgentAssistance;

