import { BREAKPOINTS, PageWidth } from "@laerdal/life-react-components";
import styled from "styled-components";

export const PageWidthFull = styled(PageWidth)`
    ${BREAKPOINTS.LARGE}{
        width: calc(100% - 128px);
    }
    ${BREAKPOINTS.MEDIUM}{
        width: calc(100% - 64px);
    }
        
    width: calc(100% - 32px);
`;