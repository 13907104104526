import { BREAKPOINTS, Button, COLORS, ComponentL, ComponentM, ComponentTextStyle, HorizontalCard, HyperLink, LoadingIndicator, PageWidth, Paginator, SearchBar, Size, SystemIcons, Table } from "@laerdal/life-react-components";
import React, { useEffect } from "react";
import { useState } from "react";
import {  useLocation } from "react-router";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import posthog from 'posthog-js';
import PartFinderApi from "../../services/api/PartFinderApi";
import { PartFinderItemDetailsDto } from "../../model/dto/partFinderItemDetailsDto";
import { PartFinderItemDto } from "../../model/dto/partFinderItemDto";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const PartFinderItemPage = () => {
  const { id } = useParams<{ id: string }>();

  let query = useQuery();

  const navigate = useNavigate()
  
  
  const [loading, setLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<PartFinderItemDetailsDto | undefined>(undefined);
  const [securityCode, setSecurityCode] = useState<string>(query.get("s") ?? '');

  useEffect(() => {
    setLoading(true);
    setDetails(undefined);

    PartFinderApi
      .GetDetails(id ?? '', securityCode)
      .then((data) => {
        setLoading(false);
        setDetails(data)
      })
      .catch(error => {
        console.error(error);
      })
  }, [id]);

  return (
    <>
      <PageWidth 
        useMaxWidth={true} >
        
        {loading && <LoadingIndicator/>}

        { !!details &&
          <>
            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Name</ComponentM>
              <ComponentM>{details.item.description}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Description</ComponentM>
              <ComponentM>{details.item.itemDescription2}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Commodity Code</ComponentM>
              <ComponentM>{details.item.commodityCode}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Country Of Origin</ComponentM>
              <ComponentM>{details.item.countryOfOrigin}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Dimensions UoM</ComponentM>
              <ComponentM>{details.item.dimensionsUoM}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Gtin</ComponentM>
              <ComponentM>{details.item.gtin}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Height</ComponentM>
              <ComponentM>{details.item.height}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Imperial Ship Weight</ComponentM>
              <ComponentM>{details.item.imperialShipWeight}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Imperial Ship Weight UoM</ComponentM>
              <ComponentM>{details.item.imperialShipWeightUoM}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Item Lifecycle</ComponentM>
              <ComponentM>{details.item.itemLifecycle}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Product Code</ComponentM>
              <ComponentM>{details.item.itemNumber}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Item Type</ComponentM>
              <ComponentM>{details.item.itemType}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Length</ComponentM>
              <ComponentM>{details.item.length}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Lot Serial</ComponentM>
              <ComponentM>{details.item.lotSerial}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Metric Ship Weight</ComponentM>
              <ComponentM>{details.item.metricShipWeight}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Metric Ship Weight Kg</ComponentM>
              <ComponentM>{details.item.metricShipWeightKg}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Metric Ship Weight UoM</ComponentM>
              <ComponentM>{details.item.metricShipWeightUoM}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Part Make Purchase</ComponentM>
              <ComponentM>{details.item.partMakePurchase}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Part Product Family</ComponentM>
              <ComponentM>{details.item.partProductFamily}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Part Product Group</ComponentM>
              <ComponentM>{details.item.partProductGroup}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Part Product Line</ComponentM>
              <ComponentM>{details.item.partProductLine}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Part Product Type</ComponentM>
              <ComponentM>{details.item.partProductType}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Part Virtual</ComponentM>
              <ComponentM>{details.item.partVirtual}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Subclass</ComponentM>
              <ComponentM>{details.item.subclass}</ComponentM>
            </Row>

            <Row>
              <ComponentM textStyle={ComponentTextStyle.Bold}>Width</ComponentM>
              <ComponentM>{details.item.width}</ComponentM>
            </Row>

            <Table
              noRowsLabel={'No parts'}
              rows={details.children || []}
              remoteOperations={false}
              selectable={true}
              onSelectionChange={(item:PartFinderItemDto) => {
                navigate(`/partFinder/${item.id}?s=${securityCode}`)
              }}
              columns={
                [
                  {
                    key: 'description',
                    name: 'Name',
                    width: '240px'
                  },{
                    key: 'quantity',
                    name: 'Quantity'
                  },{
                    key: 'itemDescription2',
                    name: 'Description'
                  },{
                    key: 'childItemType',
                    name: 'ItemType'
                  },{
                    key: 'childSubclass',
                    name: 'Subclass'
                  },{
                    key: 'childUnitOfMeasure',
                    name: 'Unit Of Measure'
                  }
                  
                  ,{
                    key: 'itemNumber',
                    name: 'Product code'
                  },{
                    key: 'itemLifecycle',
                    name: 'Lifecycle'
                  },{
                    key: 'partVirtual',
                    name: 'Virtual'
                  },{
                    key: 'partMakePurchase',
                    name: 'PMP'
                  },{
                    key: 'lotSerial',
                    name: 'Serial'
                  },{
                    key: 'countryOfOrigin',
                    name: 'Country'
                  },{
                    key: 'commodityCode',
                    name: 'Commodity code'
                  },{
                    key: 'partProductGroup',
                    name: 'Product Group'
                  },{
                    key: 'partProductFamily',
                    name: 'Product Family'
                  },{
                    key: 'partProductLine',
                    name: 'Product Line'
                  },{
                    key: 'partProductType',
                    name: 'Product Type'
                  }
                ]
              }
            />
          </>
        }
      </PageWidth>
    </>);
};

export default PartFinderItemPage;