import { BREAKPOINTS, Card, COLORS, ContentIcons, GlobalNavigationBar, SystemIcons } from '@laerdal/life-react-components';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const WarrentyAndPrivacy = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;

  align-self: center;
  display: flex;
  gap: 22px;
  max-width: 980px;
  margin: 0 16px;
`;

const WarrentyContainer = styled.div`

  ${BREAKPOINTS.LARGE}{
    width: 545px;
  }
  

  display: flex;
  flex-direction:column;
  gap: 12px;
  
  flex-shrink: 0;
  align-items: start;
  padding: 40px 50px 40px 50px;

  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: #FFF;
`;

const WarrentyHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
const WarrentyHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
`;
const WarrentyText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;
const WarrentyLink = styled.a`
  align-self: start;

  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }
  
  color: ${COLORS.primary_600};
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 147.059% */
  
  display:flex;
  align-items: center;
  svg {
    margin: 0 0 2px 0;
  }
`;

const PrivacyContainer = styled.div`
  ${BREAKPOINTS.LARGE}{
    width: 250px;
  }
  display: flex;
  flex-direction:column;
  gap: 12px;
  flex-shrink: 0;
  align-items: start;
  padding: 40px 30px 40px 30px;

  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: #FFF;
`;
const PrivacyTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
`;
const PrivacyText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;
const PrivacyLink = styled.a`
  align-self: start;

  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }
  
  color: ${COLORS.primary_600};
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 147.059% */
  
  display:flex;
  align-items: center;
  svg {
    margin: 0 0 2px 0;
  }
`;


interface BigSmallContentProps {
  big:{
    title: string,
    icon?: ReactNode,
    description: string,
    linkUrl: string,
    linkText: string
  }
  small:{
    title: string,
    description: string,
    linkUrl: string,
    linkText: string
  }
}

const BigSmallContent = (props: BigSmallContentProps) => {    
    const navigate = useNavigate()
  
    return (
        <WarrentyAndPrivacy>
            <WarrentyContainer>
              <WarrentyHeader>

                {!!props.big.icon && 
                  <div>
                    {props.big.icon}
                  </div> 
                }
                
                <WarrentyHeaderTitle>
                  { props.big.title }
                </WarrentyHeaderTitle>
              </WarrentyHeader>
              <WarrentyText>{ props.big.description }</WarrentyText>
              <WarrentyLink href={ props.big.linkUrl } target='_blank'>
                { props.big.linkText } &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                  <g clip-path="url(#clip0_2287_38645)">
                    <path d="M8.93524 11.6326C8.93524 11.8317 8.86748 11.9962 8.73198 12.1262C8.59647 12.2562 8.44022 12.3212 8.26323 12.3212C8.08624 12.3212 7.93275 12.2534 7.80278 12.1179C7.6728 11.9824 7.60781 11.8289 7.60781 11.6574V10.0148L7.74885 6.83723L6.68691 8.06509L1.14491 13.6071C1.01216 13.7398 0.857296 13.8062 0.680306 13.8062C0.497785 13.8062 0.33877 13.7357 0.203262 13.5947C0.067754 13.4536 0 13.2974 0 13.1259C0 12.9544 0.0691368 12.7996 0.20741 12.6613L5.74112 7.11101L6.96069 6.06566L3.75828 6.1984H2.14877C1.97731 6.1984 1.82521 6.13341 1.69247 6.00344C1.55973 5.87346 1.49335 5.72274 1.49335 5.55128C1.49335 5.37429 1.55558 5.21942 1.68002 5.08668C1.80447 4.95394 1.96902 4.88757 2.17366 4.88757L8.22175 4.87098C8.44298 4.87098 8.61721 4.93596 8.74442 5.06594C8.87163 5.19592 8.93524 5.36599 8.93524 5.57617V11.6326Z" fill="#276D8B"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2287_38645">
                      <rect width="11.88" height="17" fill="white" transform="matrix(1 0 0 -1 0 17.8096)"/>
                    </clipPath>
                  </defs>
                </svg>
              </WarrentyLink>
            </WarrentyContainer>
            <PrivacyContainer>
              <PrivacyTitle>{ props.small.title }</PrivacyTitle>
              <PrivacyText>{ props.small.description }</PrivacyText>
              <PrivacyLink href={ props.small.linkUrl } target='_blank'>
              { props.small.linkText } &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                  <g clip-path="url(#clip0_2287_38645)">
                    <path d="M8.93524 11.6326C8.93524 11.8317 8.86748 11.9962 8.73198 12.1262C8.59647 12.2562 8.44022 12.3212 8.26323 12.3212C8.08624 12.3212 7.93275 12.2534 7.80278 12.1179C7.6728 11.9824 7.60781 11.8289 7.60781 11.6574V10.0148L7.74885 6.83723L6.68691 8.06509L1.14491 13.6071C1.01216 13.7398 0.857296 13.8062 0.680306 13.8062C0.497785 13.8062 0.33877 13.7357 0.203262 13.5947C0.067754 13.4536 0 13.2974 0 13.1259C0 12.9544 0.0691368 12.7996 0.20741 12.6613L5.74112 7.11101L6.96069 6.06566L3.75828 6.1984H2.14877C1.97731 6.1984 1.82521 6.13341 1.69247 6.00344C1.55973 5.87346 1.49335 5.72274 1.49335 5.55128C1.49335 5.37429 1.55558 5.21942 1.68002 5.08668C1.80447 4.95394 1.96902 4.88757 2.17366 4.88757L8.22175 4.87098C8.44298 4.87098 8.61721 4.93596 8.74442 5.06594C8.87163 5.19592 8.93524 5.36599 8.93524 5.57617V11.6326Z" fill="#276D8B"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2287_38645">
                      <rect width="11.88" height="17" fill="white" transform="matrix(1 0 0 -1 0 17.8096)"/>
                    </clipPath>
                  </defs>
                </svg>
              </PrivacyLink>
            </PrivacyContainer>
          </WarrentyAndPrivacy>
    );
}

export default BigSmallContent;