import { BREAKPOINTS, Button, COLORS, ComponentL, ComponentTextStyle, ContentIcons, DropdownFilter, LoadingIndicator, PageWidth, Size, SystemIcon, SystemIcons, VerticalTabs } from "@laerdal/life-react-components";


import styled from "styled-components";
import { useEffect, useState } from "react";
import ArticleApi from "../../services/api/ArticleApi";
import { ProductLineDetailsDto } from "../../model/dto/productLineDetailsDto";
import { ScrollRestoration, useNavigate, useParams } from "react-router-dom";
import ProductTopSection from "../../commonComponents/ProductTopSection";
import Subtopics from "../../commonComponents/Subtopics";
import GetToKnow from "../../commonComponents/GetToKnow";
import Faqs from "../../commonComponents/Faqs";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import Topics from "../../commonComponents/Topics";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import { PageWidthFull } from "../../commonComponents/PageWidthFull";
import { ProductRelevantArticlesDto } from "../../model/dto/productRelevantArticlesDto";
import { useMediaMatch } from "rooks";
import VerticalTab from "../../commonComponents/VerticalTab";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
    margin-top:32px;
`;
const VerticalTabsContainer = styled.div`
  display: flex;
  width: 280px;
  flex-direction: column;
`;
const Articles = styled.div`
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap 12px;
  width:100%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const LoadingIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;
const FaqLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
`;
const Components = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap 16px;
`;
const ViewAllButton = styled(Button)`
    align-self: end;
    span {
        display: flex;
        align-items: center;
    }
`;
const ReadMoreButton = styled(Button)`
    align-self: start;
    span {
        display: flex;
        align-items: center;
    }
`;
const TopIssues = styled.div`

    ${BREAKPOINTS.LARGE}{
        display: grid;
        gap: 50px 88px;
    }
    margin-top: 16px;
    width: 100%;
    display: flex;
    gap: 32px;
    flex-direction:column;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
`;
const ArticleDetails = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
    
    ${BREAKPOINTS.LARGE}{
        border: 0px;
    }

    border-bottom: 1px solid ${COLORS.neutral_100};

`;


const ProductLinePage = () => {
    const { categoryLink, productLink } = useParams<{ categoryLink: string, productLink: string }>();

    const navigate = useNavigate()
    const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

    const [componentLinkState, setComponentLinkState] = useState<string>('');

    const [productLineLoading, setProductLineLoading] = useState<boolean>(true);
    const [productLine, setProductLine] = useState<ProductLineDetailsDto | undefined>(undefined);
 
    const [loadingArticles, setLoadingArticles] = useState<boolean>(true);
    const [articlesDto, setArticlesDto] = useState<ProductRelevantArticlesDto | undefined>(undefined);

    useEffect(() => {
        setProductLineLoading(true);
        setProductLine(undefined);

        if(categoryLink && productLink)
        {
            ArticleApi
              .GetProductLine(categoryLink, productLink)
              .then((data) => {
                setProductLineLoading(false);
                setProductLine(data);
                if(data.components.length > 0)
                    setComponentLinkState(data.components[0].link);
                    
              })
        }
    }, [categoryLink, productLink]);

    useEffect(() => {
        if(!componentLinkState)
            return;

        setLoadingArticles(true);
        setArticlesDto(undefined);

        if(categoryLink && productLink && componentLinkState)
        {
            ArticleApi
                .GetRelevantArticlesLine(categoryLink, productLink, componentLinkState)
                .then((data) => {
                    setLoadingArticles(false);
                    setArticlesDto(data);
                })
        }
    }, [categoryLink, productLink, componentLinkState]);

    return (
      <>
        { productLineLoading &&
            <LoadingIndicator />
        }

        { productLine &&
            <>
                
                <ProductTopSection
                    title={productLine.displayName}
                    description={productLine.descripton}
                    asset={productLine.mainImageUrl}
                    breadcrumb={[{
                        text: 'Home',
                        link: '/'
                    },{
                        text: productLine.categoryName,
                        link: `/category/${categoryLink}`
                    },{
                        text: productLine.displayName
                    }]}
                    buttonLink= {productLine.productDownloadsUrl ?? undefined}
                    paligoUserGuideUrl={productLine.paligoUserGuideUrl}
                />
                <PageWidthFull useMaxWidth={true} maxWidth={1024} unsetMargin={true}>
                    <PageContainer>
                        <ComponentL textStyle={ComponentTextStyle.Bold}>Most common inquiries</ComponentL>
                        <TopIssues>
                                { productLine.topIssues.map((article, index)=> 
                                    <ArticleDetails 
                                        key={`article-${index}`}>
                                        <ComponentL textStyle={ComponentTextStyle.Bold}>
                                            <span dangerouslySetInnerHTML={{ __html: article.name }}></span>
                                        </ComponentL>
                                        <ComponentL textStyle={ComponentTextStyle.Regular}>
                                            <span dangerouslySetInnerHTML={{ __html: article.text }}></span>
                                        </ComponentL>
                                        
                                        <ReadMoreButton 
                                            variant="tertiary" 
                                            size={Size.Small} 
                                            onClick={() => navigate(`/articles/Knowledge/${article.link}`)} >
                                                Read more <SystemIcons.ArrowLineRight/>
                                        </ReadMoreButton>
                                    </ArticleDetails>
                                )}
                        </TopIssues>
                        <Components>
                            <ComponentL textStyle={ComponentTextStyle.Bold}>Articles</ComponentL>
                            { !isMediumScreen && 
                                <DropdownFilter 
                                    value={componentLinkState}
                                    list={productLine.components.slice(0, 6).map((x)=> {return {
                                        value: x.link,
                                        displayLabel: x.name
                                    }})}
                                    onSelect={(value) => setComponentLinkState (value)}
                                    >
                                </DropdownFilter>
                            }
                            <Row>
                                {isMediumScreen && 
                                    <VerticalTabsContainer>
                                        {productLine.components.slice(0, 6).map((x, index)=> 
                                            <VerticalTab 
                                                text={x.name} 
                                                isActive={x.link === componentLinkState}
                                                onClick={() => setComponentLinkState(x.link)}
                                                key={`vertical-tab-${index}`}
                                                />
                                        )}
                                      

                                    </VerticalTabsContainer>
                                }
                                <Articles>
                                    { loadingArticles &&
                                        <LoadingIndicatorContainer>
                                            <LoadingIndicator ></LoadingIndicator>
                                        </LoadingIndicatorContainer>
                                    }
                                    { !loadingArticles && articlesDto?.articles.slice(0, 8).map((item, index) =>
                                        <FaqLink 
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                navigate(`/articles/Knowledge/${item.link}`);
                                            }}
                                            href={`/articles/Knowledge/${item.link}`} 
                                            key={`link-${index}`}>
                                            {item.name}
                                        </FaqLink>)
                                    }
                                </Articles>
                            </Row>
                            <ViewAllButton 
                                variant="tertiary" 
                                size={Size.Small} 
                                onClick={() => navigate(`/category/${categoryLink}/product/${productLink}/articles/${componentLinkState}`)} >
                                    View All <SystemIcons.ArrowLineRight/>
                            </ViewAllButton>
                        </Components>
                    </PageContainer>
                </PageWidthFull>
                        
                <PageWidthFull useMaxWidth={true} maxWidth={1024} >
                    <PageContainer>
                        <CantFindWhatYouNeed />

                        {   productLine.faqs.length > 0 &&
                            <Faqs 
                                title="FAQs"
                                items={
                                    productLine.faqs.map((x)=> ({
                                        text: x.name,
                                        link: `/articles/Knowledge/${x.link}`
                                    }))
                                }/>
                        }

                        <Topics 
                            title="Similar products"
                            items={
                                productLine.similarProducts.map((x)=> ({
                                    text: x.name,        
                                    asset: x.mainImageUrl,
                                    heightPx: "144px",
                                    link: `/category/${categoryLink}/product/${x.link}`
                                }))
                            }
                        />

                        <CantFindCreateCase />
                    </PageContainer>
                </PageWidthFull>
            </>
        }

        
      </>);
  };
  
  export default ProductLinePage;