import { Button, COLORS, IconButton, LoadingIndicator, PageWidth, Size, SystemIcons } from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { KnowledgeArticleDto } from "../../model/dto/knowledgeArticleDto";
import ArticleApi from "../../services/api/ArticleApi";
import { Helmet } from "react-helmet";
import { queries } from "@testing-library/react";
import React from "react";
import posthog from 'posthog-js';


const TitleContainer = styled.div`
  margin: 3px 0 0 0;
`;
const ArticleTitle = styled.h1`
  display: flex;
`;
const ArticleHtmlContainer = styled.div`
  img {
    max-width: 100%;
  }
`;

const ArticlePageContainer = styled.div`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0 0 16px 0;
  
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const EmbeddedFooter = styled.div`

  margin-top: 4px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  
  border-top: 1px solid ${COLORS.neutral_200};
`;
const Version = styled.div`
  align-self: center;
`;

const MainPageContainer = styled.div`
  display: flex;
  
  flex-direction: column;
  height: 100vh;
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EmbededArticlePage = () => {

  const { product, articleNumber } = useParams<{ product?: string, category?:string, articleNumber: string }>();
  let query = useQuery();
  const [searchQuery] = useState<string>(query.get("query") ?? '');

  const navigate = useNavigate()
  const [article, setArticle] = useState<KnowledgeArticleDto | undefined>(undefined);
  const [loadingArticle, setLoadingArticle] = useState<boolean>(true);

  useEffect(() => {

    setArticle(undefined);
    setLoadingArticle(true);

    
    ArticleApi.GetKnowledgeArticle(articleNumber, undefined, true)
      .then((data) => {
        data.publicContent = data.publicContent ? data.publicContent.replaceAll("{{currentURL}}", location.pathname) : data.publicContent;
        setLoadingArticle(false);
        setArticle(data);
        posthog.capture('ArticleView',{
          currentArticle: articleNumber,
          version: 'embedded'
        });
    
        //@ts-ignore
        setTimeout(() => {
          //@ts-ignore
          posthog?.capture('$pageview', {
            $geoip_disable: true,
            title: data?.title,
            articleNumber: data?.articleNumber,
            link: window.location.href,
            type: 'Article'
          })
        }, 100)
        });
    }, [product, articleNumber]);

  return (
    <MainPageContainer>
      <ArticlePageContainer>
        <PageWidth useMaxWidth={true} maxWidth={1024}>
          {loadingArticle && <LoadingIndicator />}
        
          {article && 
            <>
              {/* <Helmet title={article.title} /> */}
              <Button 
                size={Size.Small} 
                icon={<SystemIcons.ArrowLineLeft />} 
                variant="tertiary" 
                color={COLORS.neutral_600} 
                onClick={()=>{
                  //@ts-ignore
                  posthog?.capture('article closed', {
                    $geoip_disable: true,
                    title: article?.title,
                    articleNumber: article?.articleNumber,
                    query: searchQuery
                  })

                  // let backUrl = '/embeded/search';
                  // if(!!searchQuery) backUrl += `?query=${searchQuery}`;
                  // navigate(backUrl);
                  navigate(-1);
              }}>
                Back
              </Button>
              <ArticleTitle>   
                <TitleContainer>
                  {article.title}
                </TitleContainer>
              </ArticleTitle>
            </>
          }
          {article && <ArticleHtmlContainer dangerouslySetInnerHTML={{__html: article.publicContent ?? ''}}></ArticleHtmlContainer>}
        </PageWidth>
      </ArticlePageContainer>
        <EmbeddedFooter>
          <img src="/assets/Logo.png" width="66" height="33"  />
          <Version>
            Version 1.0.0.0
          </Version>
        </EmbeddedFooter>
    </MainPageContainer>);
};

export default EmbededArticlePage;
