import { COLORS, ContentIcons, LoadingIndicator, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProductCategoryDto } from "../../model/dto/productCategoryDto";
import ArticleApi from "../../services/api/ArticleApi";
import { ProductCategoryDetailsDto } from "../../model/dto/productCategoryDetailsDto";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ProductCategoryPage = () => {
    const { categoryLink } = useParams<{ categoryLink: string }>();

    const [categoryLoading, setCategoryLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<ProductCategoryDetailsDto | undefined>(undefined);
      
    useEffect(() => {
        if(categoryLink)
        {
            ArticleApi
              .GetProductCategory(categoryLink)
              .then((data) => {
                  setCategoryLoading(false);
                  setCategory(data);
              })
        }
    }, [categoryLink]);

    
    return (
      <>
        { categoryLoading &&
            <LoadingIndicator ></LoadingIndicator>
        }

        { category &&
            <>
                <ProductTopSection
                    title={category.name}
                    description={category.description}
                    asset={category.mainImageUrl}
                    breadcrumb={[{
                        text: 'Home',
                        link: '/'
                    },{
                        text: category.name
                    }]}
                />

                <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
                    <PageContainer>
                        <Topics 
                            items={
                                category.products.map((x)=> ({
                                        text: x.name,
                                        asset: x.mainImageUrl,
                                        heightPx: "144px",
                                        link: `product/${x.link}`
                                    }))
                            }
                        />
                        
                        <Faqs 
                            title="FAQs"
                            items={
                                category.faqs.map((x) => ({
                                        link: `/articles/Knowledge/${x.link}`,
                                        text: x.name
                                }))}                            
                            />
                        <CantFindWhatYouNeed />
                        <BigSmallContent 
                            big={{
                                title: "Laerdal Global Warranty",
                                description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                                linkText: "PDF",
                                linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                            }}
                            small={{
                                title: "Privacy Statement",
                                description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                                linkText: "Read more",
                                linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                            }}/>
                        <CantFindCreateCase />
                    </PageContainer>
                </PageWidth>
            </>
        }
      </>);
  };
  
  export default ProductCategoryPage;