import { Button, COLORS, ComponentM, ComponentS, ComponentTextStyle, ComponentXS, ComponentXXS, HorizontalCard, InputLabel, LoadingIndicator, MenuItem, PageWidth, SearchBar, Size, SystemIcon, SystemIcons } from "@laerdal/life-react-components";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ArticleApi from "../../services/api/ArticleApi";
import {useDebouncedValue} from 'rooks';
import SearchApi from "../../services/api/SearchApi";
import { SearchResultDto } from "../../model/dto/searchResultDto";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import posthog from 'posthog-js';


const Search = styled.div`
  margin: 0 16px ;
`;

const MainPageContainer = styled.div`
  display: flex;
  
  flex-direction: column;
  height: 100vh;
`;

const Version = styled.div`
  align-self: center;
`;

const BottomContainer = styled.div`
  
  padding: 16px 0;
`;

const EmbeddedFooter = styled.div`

  margin-top: 4px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  
  border-top: 1px solid ${COLORS.neutral_200};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px 0 0 0;
`;


const ArticleContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
`;

const ArticlePage = styled.div`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  padding: 0 0 16px 0;
  
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const Card = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap:8px;
 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  
  &:hover{
    box-shadow: 0px 8px 16px rgba(0,0,0,0.15);
  }
`;


const CardVideo = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap:8px;
  width: 100%;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  
  &:hover{
    box-shadow: 0px 8px 16px rgba(0,0,0,0.15);
  }
`;

const VideoTitle = styled.div`
  display:flex;
  align-items: center;
  gap: 6px;
  padding: 10px 16px 14px 16px
`;

const CardPreTitle = styled.div`
  display:flex;
  color: ${COLORS.neutral_500};
  align-items: center;
  gap: 6px;
`;

const CardSubtitle = styled(ComponentXS)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px ;
`;

const Headline = styled(ComponentS)`
  padding: 0 16px;
`;

const CardFooter = styled.div`
  width:100%;
  margin-top:8px;
  border-top: 1px solid ${COLORS.neutral_200};
  padding:16px 0 0 0;
  display:flex;
  color: ${COLORS.neutral_500};
  align-items: center;
  gap: 6px;
`;
const IFrame = styled.iframe`
  width: 100%;
`
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const EmbededSearchPage = () => {
  let query = useQuery();

  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState<string>(query.get("query") ?? '');
  const [header] = useState<string>(query.get("h") ?? '');
  const [h1] = useState<string>(query.get("h1") ?? '');
  const [h2] = useState<string>(query.get("h2") ?? '');
  const [h3] = useState<string>(query.get("h3") ?? '');
  const [h4] = useState<string>(query.get("h4") ?? '');
  const [h5] = useState<string>(query.get("h5") ?? '');
  const [h6] = useState<string>(query.get("h6") ?? '');
  const [src] = useState<string>(query.get("src") ?? '');
  const [product] = useState<string>(query.get("product") ?? '');
  const [locale] = useState<string>(query.get("locale") ?? '');
  const [token] = useState<string>(query.get("token") ?? '');
  const [source] = useState<string>(query.get("source") ?? '');
  const [releaseNotes] = useState<string>(query.get("releaseNotes") ?? '');
  const [abortController, setAbortController] = useState(new AbortController());
  

  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
  const [articles, setArticles] = useState<SearchResultDto[]>([]);

  const [searchTermDebounce] = useDebouncedValue(searchTerm, 500);

  useEffect(() => {
    const searchParams = new URLSearchParams();

    if(searchTerm) searchParams.append("query", searchTerm);
    if(header) searchParams.append("header", header);
    if(h1) searchParams.append("h1", h1);
    if(h2) searchParams.append("h2", h2);
    if(h3) searchParams.append("h3", h3);
    if(h4) searchParams.append("h4", h4);
    if(h5) searchParams.append("h5", h5);
    if(h6) searchParams.append("h6", h6);
    if(src) searchParams.append("src", src);
    if(product) searchParams.append("product", product);
    if(locale) searchParams.append("locale", locale);
    if(source) searchParams.append("source", source);
    if(releaseNotes) searchParams.append("releaseNotes", releaseNotes);
    
    navigate(`/embeded/search?${searchParams}`)

    setPage(1);
  }, [searchTermDebounce]);

  const setPage = (page: number) => {
    setArticles([])
    setArticlesLoading(true);
    
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    
    SearchApi.Search(searchTermDebounce, header, h1, h2, h3, h4, h5, h6, src, product, locale, source, newAbortController.signal)
      .then((data) => {
        setArticles(data);
        setArticlesLoading(false)
      })
      .catch(error => {
        if(!axios.isCancel(error)) {
          setArticlesLoading(false)
        }
      })
  }

  return (
    <>
      <MainPageContainer>

        <Search>
          <InputLabel inputId={'DemoSearchBarSmall'} text={'Search all articles'} size={Size.Small}/>
          <SearchBar
            
            id="DemoSearchBarSmall"
            placeholder="Ask a question or enter a keyword"
            enterSearch={() => {}}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            removeSearch={() => {
              setSearchTerm('');
            }}
            size={Size.Small}
          />
        </Search>

        <ArticlePage>
          <PageWidth useMaxWidth={true} maxWidth={1024}>     
            <SearchContainer>
              {articlesLoading && <LoadingIndicator key={'search-loadingArticles'} />}

              { !articlesLoading &&
                <ArticleContainers key="newToSimCpature">
                  <ComponentS textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_500}>Suggested articles</ComponentS>
                  {
                    articles.map((item, index) =>
                    (
                          <HorizontalCard
                            key={`card-${index}`}
                            title={item.title}
                            description={item.shortSummary}
                            action={() => { 
                                //@ts-ignore
                                posthog?.capture('Content opened', {
                                  $geoip_disable: true,
                                  title: item.title,
                                  articleNumber: item?.articleNumber, 
                                  link: `helpU.laerdal.com/embeded/articles/${item.id}`,
                                  type: 'Article',
                                  rank: index + 1,
                                })
    
                                navigate(`/embeded/articles/${item.id}?query=${searchTerm}`) 
                                }
                            }
                            tags={[{
                                label: item.minutesToRead + ' mins',
                                icon: <SystemIcons.Time key={`time-${index}`} size="24px" />,
                              },{
                                label: moment(item.updatedAt).format('LL'), 
                                icon: <SystemIcons.Calendar key={`calendar-${index}`} />
                              },
                            ]}
                          />
                    ))
                  }
                </ArticleContainers>
              }

              {!articlesLoading && articles.length == 0 && "No articles found"}
            </SearchContainer>
          </PageWidth>
          
        </ArticlePage> 
        { releaseNotes && !searchTerm && <BottomContainer>
          <Headline textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_500}>
            Additional resources
          </Headline>
          <MenuItem 
            item={{value:'1', displayLabel:'Release notes', icon: <SystemIcons.Assignments /> }} active={false} id={"1"} onClickHandler={() => navigate(`/embeded/articles/${releaseNotes}`)}> </MenuItem>
        </BottomContainer>}
        <EmbeddedFooter>
          <img src="/assets/Logo.png" width="66" height="33"  />
          <Version>
            Version 1.0.0.0
          </Version>
        </EmbeddedFooter>
      </MainPageContainer>
    </>);
};

export default EmbededSearchPage;