import { BREAKPOINTS, COLORS, ComponentM } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const Container = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  padding-left: 16px;
  cursor: pointer;

  &:hover {
      background-color: ${COLORS.primary_20};
  }
`;

const ActiveContainer = styled.div`
  align-items: center;
  display: flex;
  border-left: 4px solid ${COLORS.primary_500};
  height: 48px;
  padding-left: 14px;
  cursor: pointer;

  &:hover {
      background-color: ${COLORS.primary_20};
  }
`;

interface VerticalTabProps {
  text: string,
  isActive: boolean,
  onClick: () => void
}

const VerticalTab = ({text, isActive, onClick}: VerticalTabProps) => {      
    return (
      <>
        {!isActive && 
          <Container onClick={onClick}>
            <ComponentM>{text}</ComponentM>
          </Container>
        }
        {isActive && 
          <ActiveContainer onClick={onClick}>
            <ComponentM>{text}</ComponentM>
          </ActiveContainer>
        }
      </>

    );
}

export default VerticalTab;