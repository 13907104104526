import { Button, COLORS, ComponentM, ContentIcons, InputLabel, PageWidth, RadioButton, Size, SystemIcon, SystemIcons, Textarea, TextField } from '@laerdal/life-react-components';
import { useState } from 'react';
import styled from 'styled-components';
import ArticleApi from '../services/api/ArticleApi';
import posthog from 'posthog-js';

const Outline = styled.div`
  

  display: flex;
  padding: 24px ;
  gap: 24px;
  border-radius: 8px ;
    
  background: ${COLORS.neutral_50};
  justify-content: center;

`

const Containter = styled.div`
  with: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap:8px;
  width: 100%;
`

const Text = styled.div`
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${COLORS.neutral_200};
  margin: 16px 0;
`

const SecondPhase = styled.div`
  with: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: stretch;
  gap:8px;
  width: 100%;
`

const ThirdPhase = styled.div`
  with: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap:8px;
  width: 100%;
`

const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 64px;
  gap: 8px;
  border-radius: 64px;
  border: 2px;
  border: 1px solid ${COLORS.correct_500};
`

const StyledCheck = styled(SystemIcons.CheckMark)`
  color: ${COLORS.correct_500};
`


const TextAreaContainer = styled.div`
  width: 100%;

  div {
    height: 200px;

    input {
      height: 200px;
    }
  }
`

const CustomTextarea = styled(Textarea)`
  width: 100%;
  height: 120px;
`


interface FaqProps {
  articleNumber: string,
  positiveFeedbackCount: number;
  totalFeedbackCount: number;
}

const ArticleFeedback = ({articleNumber, positiveFeedbackCount, totalFeedbackCount}: FaqProps) => {
  const [phase, setPhase] = useState<number>(1);
  const [helpfull, setHelpfull] = useState<boolean|undefined>(undefined);
  const [moreInfoOption, setMoreInfoOption] = useState<string>('');
  const [moreInfoText, setMoreInfoText] = useState<string>('');

  
  const helpfullClick = (helpful: boolean) => {
    setMoreInfoOption('')
    setHelpfull(helpful);
    if(helpful) setPhase(2);
    else setPhase(3);
  }

  const submitClick = () => {
    posthog?.capture?.('SUPP-ArticleFeeedback', 
      {
        articleNumber: articleNumber,
        helpfull: helpfull,
        moreInfoOption: moreInfoOption,
        moreInfoText: moreInfoText
      }
    );

    ArticleApi.PostFeedback(articleNumber, helpfull)
    setPhase(4);
  }

  return (
    <Outline>
      <Containter>
        { phase < 4 &&
          <>
            <Text>Was this article helpful?</Text>
            <ButtonContainer>
              <Button icon={<SystemIcons.ThumbsUp/>} variant='primary' colorTheme={ helpfull === true ? undefined : 'dark'} onClick={() => helpfullClick(true)}>Yes</Button>
              <Button icon={<SystemIcons.ThumbsDown/>} variant='primary' colorTheme={ helpfull === false ? undefined : 'dark'} onClick={() => helpfullClick(false)}>No</Button>
            </ButtonContainer>
            
            { totalFeedbackCount > 0 &&
              <Text> {positiveFeedbackCount} out of {totalFeedbackCount} found this helpful</Text>
            }
          </>
        }
        
        { phase == 2 &&
          <SecondPhase>
            <Line/>
            <Text>Got a second? Tell us what we’re doing well.</Text>
            <RadioButton 
              id="DemoRadioButton" 
              selected={moreInfoOption == 'The content was clear and easy to understand.'} 
              select={() => {
                setMoreInfoOption('The content was clear and easy to understand.');
              }} 
              label="The content was clear and easy to understand." />
            <RadioButton 
              id="DemoRadioButton2" 
              selected={moreInfoOption == 'The article solved my problem or answered my question.'} 
              select={() => {
                setMoreInfoOption('The article solved my problem or answered my question.');
              }} 
              label="The article solved my problem or answered my question." />
            <RadioButton 
              id="DemoRadioButton3" 
              selected={moreInfoOption == 'The step-by-step instructions were helpful.'} 
              select={() => {
                setMoreInfoOption('The step-by-step instructions were helpful.');
              }} 
              label="The step-by-step instructions were helpful." />
            <RadioButton 
              id="DemoRadioButton4" 
              selected={moreInfoOption == 'Other'} 
              select={() => {
                setMoreInfoOption('Other');
              }} 
              label="Other" />
            
              <CustomTextarea placeholder='Tell us more...' size={Size.Medium} id='textarea3' onChange={ value => setMoreInfoText(value)} />
            <ButtonContainer>
              <Button variant='primary' onClick={() =>submitClick()} >Submit</Button>
              <Button variant='secondary' onClick={() =>submitClick()}>Skip</Button>
            </ButtonContainer>
          </SecondPhase>
        }
        
        { phase == 3 &&
          <SecondPhase>
            <Line/>
            <Text>Sorry about that, can you tell us more?</Text>
            <RadioButton 
              id="DemoRadioButton5" 
              selected={moreInfoOption == 'The content was outdated.'} 
              select={() => {
                setMoreInfoOption('The content was outdated.');
              }} 
              label="The content was outdated." />
            <RadioButton 
              id="DemoRadioButton6" 
              selected={moreInfoOption == 'The article didn’t address my problem or question.'} 
              select={() => {
                setMoreInfoOption('The article didn’t address my problem or question.');
              }} 
              label="The article didn’t address my problem or question." />
            <RadioButton 
              id="DemoRadioButton7" 
              selected={ moreInfoOption == 'The instructions were incomplete or incorrect.'} 
              select={() => {
                setMoreInfoOption('The instructions were incomplete or incorrect.');
              }} 
              label="The instructions were incomplete or incorrect." />
            <RadioButton 
              id="DemoRadioButton8" 
              selected={ moreInfoOption == 'Other'} 
              select={() => {
                setMoreInfoOption('Other');
              }} 
              label="Other" />
              
              <CustomTextarea placeholder='Is there anything we can do to improve?'  size={Size.Medium} id='textarea' onChange={ value => setMoreInfoText(value)}  />

            <ButtonContainer>
              <Button variant='primary' onClick={() => submitClick()}>Submit</Button>
              <Button variant='secondary' onClick={() => submitClick()}>Skip</Button>
            </ButtonContainer>
          </SecondPhase>
        }

        { phase == 4 &&
          <ThirdPhase>
            <CheckContainer>
              <StyledCheck size='32px'/>
            </CheckContainer>
            <Text>Thank you for your feedback</Text>
          </ThirdPhase>
        }
      </Containter>
    </Outline>
  );
}

export default ArticleFeedback;

