import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
// Import your reducers here
import rootReducer from './reducers';

const store = configureStore({
    reducer: {
        user: userReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
